<template>
  <div>
    <v-dialog
      v-model="dialogTimeSlotAttendees"
      max-width="1000px"
      @input="handleDialogClose"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-app-bar flat class="white">
              <v-toolbar-title class="headline font-weight-bold">
                Attendees ({{ attendeesListMeta.total || 0 }})
              </v-toolbar-title>

              <v-spacer></v-spacer>
              <v-text-field
                v-model="filter.search"
                class="mr-2"
                ref="searchField"
                flat
                hide-details
                solo
                rounded
                clearable
                background-color="grey lighten-3"
                label="Search attendee"
                @input="search"
              ></v-text-field>
              <v-btn
                color="primary"
                class="appbar__btn mr-2"
                height="40px"
                @click="exportAttendees"
              >
                <v-icon class="mr-2">{{ icons.export }}</v-icon>
                Export
              </v-btn>

              <v-btn
                color="primary"
                class="appbar__btn"
                height="40px"
                @click="openModal"
                v-if="permission"
              >
                <v-icon class="mr-2">{{ icons.add }}</v-icon>
                Add Attendees
              </v-btn>
            </v-app-bar>
            <v-data-table
              :headers="headers"
              :items="attendeesList"
              :options.sync="options"
              :loading="loading"
              :items-per-page="10"
              :footer-props="{
                'items-per-page-options': [5],
              }"
              @update:options="fetchAttendeesList"
              :sort-by.sync="defaultFilter"
              :sort-desc.sync="sortOrder"
              :no-data-text="noDataText"
            >
              <template v-slot:item.event_confirmation.created_at="{ item }">
                {{ formatDate(item.event_confirmation.created_at) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  size="default"
                  v-if="permission"
                  @click="deleteItem(item)"
                  >{{ icons.delete }}</v-icon
                >
              </template>
              <template #no-data>
                {{ noDataText }}
              </template>
            </v-data-table>
            <EventAddAttendee
              :is-open="openAddAttendeeDialog"
              :building-id="buildingId"
              :event-id="eventId"
              :time-slot-id="timeSlotId"
              @add-attendee="updateTimeSlotAttendees"
              @close-modal="closeModal"
            />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteAttendee" max-width="600px">
      <v-form
        ref="formDeleteAttendee"
        class="faq-form__form"
        @submit="submitDeleteAttendee"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Are you sure to delete this attendee?</span>
          </v-card-title>
          <v-card-actions class="pb-5">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelDialogDelete()">
              NO
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submitDeleteAttendee"
              name="submitDeleteAttendee"
            >
              YES
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { dateOnlyFormat } from '@/utils/date'
import DATETIME_FORMAT from '@/utils/enums/DatetimeFormat'
import { mdiPlus, mdiDelete } from '@mdi/js'
import EventAddAttendee from './EventAddAttendee.vue'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import debounce from 'lodash/debounce'

export default {
  components: {
    EventAddAttendee,
  },
  emits: ['close-modal', 'added-attendee'],
  mixins: [SnackbarMixin, ErrorHandlerMixin],
  props: {
    eventId: String,
    buildingId: Number,
    timeSlotId: String,
    isOpen: Boolean,
    permission: Boolean,
  },
  data() {
    return {
      defaultFilter: 'full_name',
      sortOrder: false,
      filter: {
        search: '',
      },
      headers: [
        {
          text: 'Name',
          value: 'full_name',
        },
        {
          text: 'Guest(s)',
          value: 'event_confirmation.plus',
        },
        {
          text: 'Property',
          value: 'property.name',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Phone',
          value: 'phone_number',
        },
        {
          text: 'Join Date',
          value: 'event_confirmation.created_at',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ],
      dialogTimeSlotAttendees: false,
      dialogDeleteAttendee: false,
      loading: false,
      options: {},
      icons: {
        add: mdiPlus,
        delete: mdiDelete,
        export: 'mdi-export-variant',
      },
      openAddAttendeeDialog: false,
      noDataText: 'There are no attendees',
      selectedItem: null,
    }
  },
  watch: {
    isOpen(newValue, oldValue) {
      this.dialogTimeSlotAttendees = newValue
      if (newValue) {
        this.fetchAttendeesList()
      }
    },
  },
  computed: {
    ...mapState({
      attendeesList: (state) => state.event.attendees.list,
      attendeesListMeta: (state) => state.event.attendees.listMeta,
    }),
  },
  methods: {
    ...mapActions({
      getAttendeesList: 'event/getAttendeesByEventTimeSlot',
      deleteAttendee: 'event/deleteAttendee',
      clearTimeSlotAttendees: 'event/clearTimeSlotAttendees',
      exportAttendeeList: 'event/exportTimeSlotAttendees',
    }),
    ...mapMutations({
      clearTimeSlotAttendees: 'event/clearTimeSlotAttendees',
    }),
    formatDate(date) {
      return dateOnlyFormat(date, DATETIME_FORMAT.shortDateFormat)
    },
    openModal() {
      this.openAddAttendeeDialog = true
    },
    closeModal() {
      this.openAddAttendeeDialog = false
    },

    cancelDialogDelete() {
      this.dialogDeleteAttendee = false
      this.selectedItem = null
    },

    deleteItem(item) {
      this.dialogDeleteAttendee = true
      this.selectedItem = item
    },

    updateTimeSlotAttendees() {
      this.openAddAttendeeDialog = false
      this.fetchAttendeesList()
      this.$emit('added-attendee')
    },
    handleDialogClose() {
      this.clearTimeSlotAttendees()
      this.$emit('close-dialog')
    },

    search: debounce(function () {
      this.clearTimeSlotAttendees()
      this.fetchAttendeesList()
    }, 600),

    async submitDeleteAttendee() {
      const { id } = this.selectedItem
      this.loading = true
      try {
        await this.deleteAttendee({
          time_slot_id: this.timeSlotId,
          event_id: this.eventId,
          id: id,
        })
      } catch (err) {
        this.showSnackbar(this.getErrorMessage(err), false)
      } finally {
        this.loading = false
        this.dialogDeleteAttendee = false
        this.fetchAttendeesList()
        this.$emit('delete-attendee')
      }
    },

    async fetchAttendeesList(options) {
      try {
        this.loading = true
        const currentPage = options?.page || 1
        await this.getAttendeesList({
          time_slot_id: this.timeSlotId,
          event_id: this.eventId,
          page: currentPage,
          ...(this.filter.search ? { search: this.filter.search } : {}),
        })
      } catch (err) {
        this.showSnackbar(this.getErrorMessage(err), false)
      } finally {
        this.loading = false
      }
    },
    async exportAttendees() {
      try {
        const response = await this.exportAttendeeList({
          event_id: this.eventId,
          time_slot_id: this.timeSlotId,
        })
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: 'application/vnd.ms-excel',
          })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'attendee_list.xlsx')
        document.body.appendChild(link)
        link.click()
        this.showSnackbar('File successfully downloaded')
      } catch (err) {
        this.showSnackbar(
          this.getErrorMessage(err, 'Oops! Something went wrong'),
          false
        )
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style></style>
