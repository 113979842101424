const GUEST_OPTIONS = [
  {
    text: '0',
    value: 0,
  },
  {
    text: '1',
    value: 1,
  },
  {
    text: '2',
    value: 2,
  },
  {
    text: '3',
    value: 3,
  },
]

export default GUEST_OPTIONS
