<template>
  <v-dialog
    v-model="openModal"
    max-width="800px"
    class="white"
    @input="handleDialogClose"
  >
    <v-card>
      <v-form
        ref="form"
        class="add-attendee-form__form px-5"
        @submit.prevent="submit"
      >
        <v-card-title>
          <span class="text-h5 py-5">{{ header }}</span>
        </v-card-title>
        <v-card-text class="pb-0 mb-6">
          <v-container class="pb-0">
            <v-row>
              <v-col cols="12" md="4">
                <label class="text-field-label">Property</label>
                <v-select
                  class="select-building"
                  :items="propertyList"
                  flat
                  solo
                  @input="handlePropertySelect"
                  :loading="loading"
                />
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-field-label">Attendee</label>
                <v-select
                  class="select-user"
                  v-model="form.user_id"
                  :items="userList"
                  :error-messages="form.$getError('user_id')"
                  flat
                  solo
                  :loading="loading"
                  :disabled="loading"
                />
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-field-label">Guest(s)</label>
                <v-select
                  v-model="form.plus"
                  flat
                  solo
                  :items="guestOption"
                  :error-messages="form.$getError('plus')"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-5 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1 "
            text
            :loading="form.$busy"
            :disabled="form.$busy"
            @click="closeDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="blue darken-1 text-uppercase"
            :loading="form.$busy"
            :disabled="form.$busy"
            @click="submit"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
/**
 * ==================================================================================
 * Booking Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import GUEST_OPTION from '@/utils/enums/GuestOption'

export default {
  mixins: [SnackbarMixin, ErrorHandlerMixin],
  emits: ['close-modal', 'add-attendee'],
  props: {
    eventId: String,
    buildingId: String,
    timeSlotId: String,
    isOpen: Boolean,
  },

  data() {
    const initForm = {
      time_slot_id: null,
      event_id: null,
      user_id: null,
      plus: 0,
    }
    return {
      form: new Form(initForm),
      options: {},
      loading: false,
      openModal: this.isOpen,
      properties: [],
    }
  },
  mounted() {
    this.fetchProperties()
  },
  watch: {
    isOpen(newValue, oldValue) {
      this.openModal = newValue
    },
    buildingId(newValue, oldValue) {
      this.fetchProperties()
    },
  },
  computed: {
    ...mapState({
      users: (state) => state.timeslot.timeslotAvailableUsers,
    }),
    header() {
      return 'Add Attendee'
    },
    guestOption() {
      return GUEST_OPTION
    },
    userList() {
      if (this.users) {
        return this.users.map((user) => {
          return { text: user.full_name, value: user.id }
        })
      } else {
        return []
      }
    },

    propertyList() {
      if (this.properties) {
        return this.properties.map((property) => {
          return { text: property.name, value: property.id }
        })
      } else {
        return []
      }
    },
  },
  methods: {
    ...mapActions({
      addAttendee: 'event/addAttendeeToTimeSlot',
      getUsers: 'timeslot/getTimeSlotListAvailableUsers',
      getPropertiesByBuilding: 'property/getPropertiesByBuilding',
    }),
    closeDialog() {
      this.form = new Form(this.initForm)
      this.$emit('close-modal')
    },
    handleDialogClose() {
      this.form = new Form(this.initForm)
      this.$emit('close-modal')
    },

    handlePropertySelect(propertyId) {
      this.form = new Form({
        ...this.form,
        user_id: null,
        plus: 0,
      })
      this.fetchUsers(propertyId)
    },

    async fetchUsers(propertyId) {
      this.loading = true

      await this.getUsers({
        eventId: this.eventId,
        id: this.timeSlotId,
        propertyId: propertyId,
        buildingId: this.buildingId,
      })
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    async fetchProperties() {
      if (this.loading) return
      this.loading = true
      if (this.buildingId) {
        await this.getPropertiesByBuilding(this.buildingId)
          .then((data) => {
            this.properties = data
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.loading = false
      }
    },

    async submit() {
      if (this.form.$busy) return
      this.form.time_slot_id = this.timeSlotId
      this.form.event_id = this.eventId
      this.form.$busy = true
      this.form.$clearErrors()

      await this.addAttendee(this.getFormData())
        .then(() => {
          this.form.$busy = false
          this.$emit('add-attendee')
          this.showSnackbar('Attendee successfully added!')
          this.form = new Form(this.initForm)
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    getFormData() {
      let form = this.form
      if (this.isUpdate) {
        form.user_id = this.user.id
      }
      return form
    },
  },
}
</script>
